<template>
  <div class="inspector_user">
    <UserInfo>
      <div class="top_main_info inspector_bg">
        <div class="avatar avatar_testing" @click="show=true"></div>
        <div class="un_vip_user" @click="show=true">
          <div class="un_vip_name">{{ userName }}</div>
        </div>
      </div>
    </UserInfo>
    <nav>
      <div class="nav_l" @click="toTestReportSearch">
        <img src="../assets/Group 212.png">
        <p>检测报告填写</p>
      </div>
      <div class="nav_c" @click="toDetectionHistory">
        <img src="../assets/Group 233.png">
        <p>检测历史</p>
      </div>
      <div class="nav_r" @click="toTestReportSearchAgain">
        <img src="../assets/Group 361.png">
        <p>复检</p>
      </div>
    </nav>
    <p class="tip">工作安排</p>
    <div class="work_item item_loading" v-if="work_loading">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="work_item" v-for="v in workRecord" @click="toWorkRecord(v.mid)" v-else>
      <div class="title">{{ v.member }}</div>
      <div class="address">{{ v.address }}</div>
      <div class="leader">{{ v.linkman }}</div>
      <div class="tel">{{ v.contact }}</div>
      <div class="inspector">
        <span>{{ v.leader }}</span>
        <span>{{ v.tester }}</span>
        <span>{{ v.writer }}</span>
      </div>
      <div class="time">{{ v.datetime }}</div>
    </div>
    <div class="work_item" v-if="workRecord.length<=0 && !work_loading"> <!-- 当creater未拿到数据时也会显示 添加!work_loading 已解决 -->
      <van-empty  description="已经全部完成啦！" :image="smiling"></van-empty>
    </div>

    <div class="mb" @click="show=false" v-if="show">
      <div class="pannel">
        <div class="avatar"></div>
        <div class="vip_name">{{userName}}</div>
        <div class="btn" @click="exit">退出登录</div>
      </div>
    </div>

  </div>
</template>

<script>
import UserInfo from "../components/UserInfo";
import {GetWorkRecord} from "../axios/api";
import {Empty, Loading, Toast} from "vant";
import Smiling from "../assets/smiling.png"

export default {
  name: "TestingUser",
  beforeRouteEnter(to,from,next){
    //判断用户身份是否正确 检测员 为 3
    if(localStorage.getItem("identity")==3){
      next(true)
    }else{
      localStorage.removeItem("identity");
      localStorage.removeItem("userName");
      localStorage.removeItem("token");
      next("/testingLogin");
    }
  },
  components:{
    UserInfo,
    [Empty.name]:Empty,
    [Loading.name]:Loading
  },
  created() {
    if(localStorage.getItem("userName")&&localStorage.getItem("token")){
      this.userName=localStorage.getItem("userName");
      // 可以验证token是否有效
      GetWorkRecord({
        token:localStorage.getItem("token")
      }).then(respose=>{
        if(respose.state==="1"){
          console.log(respose);
          this.workRecord=respose.data
        }
        this.work_loading=false
      }).catch(error=>{
        console.log(error);
      })
    }else{
      //  未在缓存里找到用户信息，提示用户需要重新登录
      Toast({message:"请重新登录",icon:"cross"})
      this.$router.push("/testingLogin")
    }
  },
  data () {
    return {
      userName:'',
      workRecord:[],
      smiling:Smiling,
      work_loading:true,
      show:false
    }
  },
  methods:{
    toTestReportSearch(){
      this.$router.push({name:"TestReport2"})
    },
    toTestReportSearchAgain(){
      this.$router.push({name:"TestReport2",query:{again:'1'}})
    },
    toDetectionHistory(){
      this.$router.push("/detectionHistory")
    },
    toWorkRecord(mid){
      this.$router.push({name:"TestReport",params:{mid:mid}})
    },
    exit(){
      Toast.success({message:"退出登录"})
      localStorage.removeItem("identity");
      localStorage.removeItem("userName");
      localStorage.removeItem("token");
      this.$router.push("/testingLogin");
    }
  }
}
</script>

<style scoped>
.inspector_user {
  padding-bottom: 62px;
}
nav {
  width: 690px;
  height: 250px;
  margin: 82px auto 0;
  display: flex;
  justify-content: space-between;
}
nav .nav_l,
nav .nav_r,
nav .nav_c{
  width: 219px;
  height: 202px;
  background: #FFFFFF;
  box-shadow: 0px 4px 13px 5px rgba(112, 112, 112, 0.06);
  border-radius: 21px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
nav .nav_l img,
nav .nav_r img,
nav .nav_c img{
  width: 64px;
}
nav .nav_l p,
nav .nav_r p,
nav .nav_c p{
  margin: 0;
  position: relative;
  top: -7px;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #283B51;
}
.tip {
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.69);
  line-height: 35px;
  margin: 84px 0 29px 30px;
}
.work_item {
  width: 690px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: 12px auto 0;
}
.work_item .title {
  width: 640px;
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.79);
  line-height: 38px;
  letter-spacing: 3px;
  padding: 33px 25px 10px;
  /*隐藏滚动条*/
  display: inline-block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;/*IE 10+*/
  overflow: -moz-scrollbars-none;/*Firefox*/
}
.work_item .title::-webkit-scrollbar {
  width: 0 !important;
} /*不兼容IE*/
.work_item .address {
  width: 620px;
  min-height: 39px;
  font-size: 28px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.57);
  line-height: 33px;
  letter-spacing: 2px;
  background-image: url("../assets/Group 194.png");
  background-size: 29px 30px;
  background-repeat: no-repeat;
  background-position: 21px 2px;
  padding-left: 64px;
}
.work_item .leader {
  width: 620px;
  height: 39px;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.79);
  line-height: 33px;
  padding-left: 64px;
  margin-top: 29px;
  background-image: url("../assets/Group 218.png");
  background-size: 23px 27px;
  background-repeat: no-repeat;
  background-position: 24px 7px;
}
.work_item .tel {
  width: 620px;
  margin-top: 10px;
  padding-left: 62px;
  background-image: url("../assets/tel.png");
  background-size: 27px 27px;
  background-repeat: no-repeat;
  background-position: 25px 4px;
  height: 39px;
  font-size: 28px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.57);
  line-height: 33px;
  letter-spacing: 2px;
}
.work_item .inspector {
  width: 620px;
  height: 39px;
  font-size: 28px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.79);
  line-height: 33px;
  padding-left: 64px;
  margin-top: 29px;
  background-image: url("../assets/admin.png");
  background-size: 29px 29px;
  background-repeat: no-repeat;
  background-position: 22px 5px;
  /*直接截断吧*/
  overflow: hidden;
  white-space: nowrap;
}
/* !*隐藏滚动条*!
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling:touch;
    -ms-overflow-style: none; !*IE 10+*!
    overflow: -moz-scrollbars-none; !*Firefox*!
    &::-webkit-scrollbar { width: 0 !important } !*不兼容IE*!*/
.work_item .inspector span {
  margin-right: 42px;
}
.work_item .time {
  width: 620px;
  height: 36px;
  font-size: 26px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.46);
  line-height: 30px;
  padding-left: 25px;
  margin-top: 29px;
}
>>>.van-empty{
  width: 690px;
  height: 432px ;
}
>>>.van-empty__image{
  width: 160px;
  height: 160px;
}
>>>.van-empty__description{
  font-size: 14px;
  width: 590px;
  text-align: center;
  padding: 0;
}
.item_loading{
  width:690px;height: 51px;padding: 190px 0
}

.mb {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.36);
  box-shadow: 0px 2px 7px 3px rgba(112, 112, 112, 0.06);
}
.mb .pannel {
  width: 614px;
  height: 546px;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px 6px rgba(112, 112, 112, 0.06);
  border-radius: 54px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  position: absolute;
  top: 336px;
  left: 68px;
  background: url("../assets/Group 360.png") center center / 100% 100% no-repeat content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mb .pannel .avatar {
  margin-top: 93px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-image: url("../assets/avatar_cinema.png");
  background-size: 120px;
  background-position: 0 0;
  background-repeat: no-repeat;
}
.mb .pannel .vip_name {
  margin: 10px 0 123px;
  font-size: 32px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #000000;
  line-height: 45px;
}
.mb .pannel .btn {
  width: 375px;
  height: 81px;
  background: #5A77FD;
  border-radius: 41px;
  opacity: 0.87;
  font-size: 32px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 81px;
  text-align: center;
}

</style>
